import React, { useEffect, useState} from "react";
import "../../index.css";
import { MathComponent } from "mathjax-react";
import NumPad from "../../components/NumPad";
import "katex/dist/katex.min.css"
import {BlockMath} from "react-katex"
import { getProblemInTex } from "../../utils/getProblemInTex/getProblemInTex";

function Problem(props) {
  const {
    onCheck,
    totalNumberOfProblems,
    currentNumberOfProblems,
    problem,
    problemType,
  } = props;

  const [ mathExpr, setMathExpr] = useState('\\')

  useEffect(() => {
    if (problem) {
      setMathExpr(getProblemInTex(problem,problemType))
    }
  },[problem])

  const handleSubmit = (answer) => {
    onCheck(answer);
  };
  
  return (
    <div>
      <div style={{ color: "var(--gray)" }}>
        {`${currentNumberOfProblems}/${totalNumberOfProblems}`}
      </div>
      <div className="problem-txt">
      <BlockMath math={mathExpr} />
      </div>
      <div className="block-row">
        <div className="block ">
          <NumPad handleSubmit={handleSubmit} problemType={problemType}/>
        </div>
      </div>
    </div>
  );
}

export default Problem;

/*
  @key(name: "gamesByDate", fields: ["id", "createdAt"], queryField: "gamesByDate")
*/
