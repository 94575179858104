import React, { Component } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import i18n from "../../i18n";

export default class ChangeLanguage extends Component {
  handleClickEn() {
    i18n.changeLanguage("en");
  }

  handleClickRus() {
    i18n.changeLanguage("rus");
  }

  render() {
    return (
      <ButtonGroup className="mb-2">
        <Button onClick={this.handleClickEn}>english</Button>
        <Button onClick={this.handleClickRus}>русский</Button>
      </ButtonGroup>
    );
  }
}
