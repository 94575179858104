import React from 'react';
import { UserContext } from '../../app';
import { Auth } from 'aws-amplify';
import GoogleButton from 'react-google-button'

const Login = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('`useUser` must be within a `UserProvider`');
  }
  return (
    <div>
      <GoogleButton onClick={() => Auth.federatedSignIn({ provider: 'Google' })} />
    </div>
  );
};

export default Login;
