import React from "react";
import "../../index.css"

function NumberInCircle({ value, color, handleClick = null }) {
  const onClick = () => {
    handleClick && handleClick(value);
  };
  return (
    <div className={`number ${color}`} onClick={onClick} style={{ color, borderColor: color }}>
      {value}
    </div>
  );
}

export default NumberInCircle;
