import { Auth } from 'aws-amplify'
import nsOpenDataConfig from '../config/nsOpenData'

const API_URL = 'https://52gillr2sk.execute-api.us-east-2.amazonaws.com/dev';


function jsonResponse(response) {
  try {
    const res = response.json();
    return res;
  } catch (e) {
    return response;
  }
}
export const request = async (path, options = {}, data = {}) => {
  try {
    const currentSession = await  Auth.currentSession();
    const jwtToken = currentSession.getIdToken().getJwtToken();
    const params = {
      headers: {
        Authorization: jwtToken,
        'content-type': "application/json",
      },
      ...options,
    }
    if (data && Object.keys(data).length) {
      params.body = JSON.stringify(data)
    }
    const res = await fetch(API_URL + path, params);
    const result = await jsonResponse(res)
    if (result && result.success) {
      return  result.data;
    } else {
      throw result.error
    }
  } catch (error) {
    throw error.message ? error.message : error.name ? error.name : error
  }
};

export const nsOpenDataRequest = async (path, options = {}, data = {}) => {
  try {
    const params = {
      type: "GET",
      data: {
        "$limit" : 5000,
        "$$app_token" : nsOpenDataConfig.appToken
      }
    }

    const res = await fetch( "https://data.novascotia.ca/resource/iyap-ttn5.json?year=2020-2021&$select=school_code,school_name", params);
    const result = await jsonResponse(res)
    return result
  } catch (error) {
    console.log("🚀 ~ file: request.js ~ line 62 ~ nsOpenDataRequest ~ error", error)
    throw error.message ? error.message : error.name ? error.name : error
  }
};