import React, { useReducer } from "react";

const RedirectReducer = (state, action) => {
  switch (action.type) {
    case 'SET_REDIRECT_URL':
      return {
        ...state,
        redirectUrl: action.url,
      }
    default:
      return state
  }
}

const initialState = {
  redirectUrl: JSON.parse(localStorage.getItem('redirectUrl')) || null,
}
// set the defaults
export const RedirectContext = React.createContext(initialState);

export const RedirectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RedirectReducer, initialState);

  function setRedirectUrl(url) {
    localStorage.setItem('redirectUrl', JSON.stringify(url))
    dispatch({
      type: "SET_REDIRECT_URL",
      url
    });
  }

  return (
    <RedirectContext.Provider
      value={{
        redirectUrl: state.redirectUrl,
        setRedirectUrl
      }}
    >
      {children}
    </RedirectContext.Provider>
  );
};
export default RedirectProvider;