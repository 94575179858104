import React, { useState, useRef, useEffect, useCallback } from "react";
import NumberInCircle from "../NumberInCircle";
import Check from "../Icons/Check";
import WithSizes from "react-sizes";
import "../../index.css"

const DELETE = "delete";
const SUBMIT = "submit";
const arr = ["1", "2", "3", "4", "5", "6", "7", "8", "9", DELETE, "0", SUBMIT];
const NULL_CLICKED_ITEM = 20;

const NumPad = (props) => {
  const { handleSubmit, problemType, isMobile } = props;
  const [userInput, setUserInput] = useState("");
  const [userInput2, setUserInput2] = useState("");
  const [clickedItem, setClickedItem] = useState(NULL_CLICKED_ITEM);
  const [inputFocus, setInputFocus] = useState(0);
  const inputRef = useRef(null);
  const input2Ref = useRef(null);

  const ICON_SIZE = isMobile ? 50 : 75;

  useEffect(() => {
    !isMobile && inputRef.current && inputRef.current.focus();
  }, [isMobile]);

  useEffect(() => {
    let timeout = null;
    timeout = setTimeout(() => {
      setClickedItem(NULL_CLICKED_ITEM);
    }, 300);

    return () => clearTimeout(timeout);
  }, [clickedItem]);

  const handleChangeInput = (e) => {
    let value = e.target.value;
    setUserInput(value);
    setClickedItem(value.slice(-1));
  };

  const handleChangeInput2 = (e) => {
    let value = e.target.value;
    setUserInput2(value);
    setClickedItem(value.slice(-1));
  };

  const onClick = (value) => {
    setClickedItem(value);
    setUserInput((userInput) => userInput + value);
    !isMobile && inputRef.current && inputRef.current.focus();
  };

  const onDelete = () => {
    setClickedItem(DELETE);
    setUserInput((userInput) => userInput.slice(0, -1));
    !isMobile && inputRef.current && inputRef.current.focus();
  };

  const onSubmit = useCallback(() => {
    if (userInput.length === 0) return null;
    if(problemType === "System of equations" && userInput2.length === 0) {
      setInputFocus(1)
      input2Ref.current.focus();
      return null;
    }
    setUserInput("");
    setUserInput2("");
    !isMobile && inputRef.current && inputRef.current.focus();
    if(problemType === "Mental math"){
      handleSubmit(userInput)
    }
    else if(problemType === "System of equations"){
      handleSubmit([userInput,userInput2]);
    }
  }, [userInput, userInput2, isMobile, handleSubmit, inputFocus]);


  useEffect(() => {
    const eventHandler = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
      if (event.key === "Backspace") {
        setClickedItem(DELETE);
      }
    };
    window.addEventListener("keydown", eventHandler);
    return () => {
      window.removeEventListener("keydown", eventHandler);
    };
  }, [onSubmit]);

  return (
    <div className='num-pad'>
      <div style={{display:"flex"}}>
        <label className="label">x =</label>
        <input ref={inputRef} className='num-pad-input' type='text' value={userInput} autoFocus={!isMobile} disabled={isMobile} onChange={handleChangeInput}></input>
      </div>
      {problemType === "System of equations"? 
        <div style={{display:"flex"}}>
          <label className="label">y =</label>
          <input ref={input2Ref} className='num-pad-input' type='text' value={userInput2} disabled={isMobile} onChange={handleChangeInput2}></input> 
        </div>
        : null}
      <div className='num-pad-container'>
        {arr.map((item) => {
          if (item === DELETE) {
            return (
              <NumberInCircle
                key={item}
                value="<"
                handleClick={onDelete}
                color={
                  clickedItem.length > 0 && clickedItem === item
                    ? "var(--green)"
                    : "var(--gray)"
                }
              />
            );
          }
          if (item === SUBMIT) {
            return (
              <div key={item} onClick={onSubmit} style={{ cursor: "pointer" }}>
                <Check
                  color={userInput.length > 0 ? "var(--green)" : "var(--gray)"}
                  size={ICON_SIZE}
                />
              </div>
            );
          }
          return (
            <NumberInCircle
              key={item}
              value={item}
              handleClick={onClick}
              color={
                clickedItem.length > 0 && +clickedItem === +item
                  ? "var(--green)"
                  : "var(--gray)"
              }
            />
          );
        })}
      </div>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
});

export default WithSizes(mapSizesToProps)(NumPad);
