import prolbemSolver from "../problemSolver";

function randomNumber(from, to) {
    let answ = Math.floor(Math.random() * (to - from)) + from;
    return answ;
}

export default function systemOfEquationsGenerator(conditions){
    while(true){
        const rows=2;
        const cols=2;
        let b=[]
        let c=[]
        for(let r=0;r<rows;r++){
            let a=[]
            for(let c=0;c<cols;c++){
                a.push(randomNumber(1,20))
            }
            b.push(a)
            c.push(randomNumber(1,20))
        }
        let answ = prolbemSolver([b,c],conditions.problemType,false)
        if(answ !== "sungular matrix" && answ.every((e)=>Number.isInteger(e)))
            return [b,c]
    }
}
