import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import GamesTable from "./Games";
import { withRouter } from "react-router-dom";
import "../../index.css";
import Groups from "./Groups";
import GroupDetails from "./Groups/GroupDetails";
import UserInfo from "./UserInfo";
import NewGroup from "./Groups/NewGroup";
import JoinGroup from "./Groups/JoinGroup";

function Profile(props) {
  const { user, handleSignOut } = props;

  return (
    <div>
      <div className="container">
        <div className="circle" />
        <div className="circle2" />
      </div>
      <div className="profileContainer">
        <div className="sideNav">
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <NavLink to="/profile" exact activeStyle={{ color: "#dee2e6" }}>
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile/games" activeStyle={{ color: "#dee2e6" }}>
                Games
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile/groups" activeStyle={{ color: "#dee2e6" }}>
                Groups
              </NavLink>
            </li>
            <div className="divider" />
            <li>
              <NavLink to="/" onClick={handleSignOut}>
                Sign out
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="main">
          <Switch>
            <Route exact path="/profile">
              <UserInfo user={user} />
            </Route>
            <Route exact path="/profile/groups">
              <Groups user={user} />
            </Route>
            <Route exact path="/profile/groups/new">
              <NewGroup user={user} />
            </Route>
            <Route exact path="/profile/groups/join">
              <JoinGroup user={user} />
            </Route>
            <Route exact path="/profile/groups/:id">
              <GroupDetails user={user} />
            </Route>
            <Route path="/profile/games">
              <GamesTable user={user} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Profile);
