const letters = ['x','y','z','w','a','b','c','d','e','f'];

export const getAnswerInTex = (answer,problemType) => {
    if(!answer) return "\\";
    let answerInTex = "";
    if(problemType === "Mental math"){
        answer = String(answer)
        for (let i = 0; i < answer.length; i++) {
            answerInTex += answer[i];
        }
    }
    else if(problemType === "System of equations"){
        answerInTex+=`\\begin{cases}`
        for(let i=0;i<answer.length;i++){
            answerInTex+=letters[i]
            answerInTex+=`=`
            answerInTex+=answer[i]
            answerInTex+=`\\\\`
        }
        answerInTex+=`\\end{cases}`
    }
    return answerInTex
}


export const getProblemInTex = (problem,problemType) => {
    if (!problem) return "\\";
    let problemInTex = "";
  
    if(problemType==="Mental math"){
      for (let i = 0; i < problem.length - 1; i++) {
        if (problem[i] === "*") problemInTex += `\\times`;
        else if (problem[i] === "/") problemInTex += `\\div`;
        else problemInTex += problem[i];
      }
    }
    else if(problemType==="System of equations"){
      problemInTex+=`\\begin{cases}`
      for(let i=0;i<problem[0].length;i++){
        for(let j=0;j<problem[0][i].length;j++){
          problemInTex+=problem[0][i][j];
          problemInTex+=letters[j];
          if(j+1<problem[0][i].length) problemInTex+=`+`
          else problemInTex+=`=`
        }
        problemInTex+=problem[1][i]
        problemInTex+=`\\\\`
      }
      problemInTex+=`\\end{cases}`
    }
  
    return problemInTex;
  };