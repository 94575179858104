import React from 'react';
import * as dayjs from 'dayjs';
import styled from '@emotion/styled';
import NextIcon from '../Icons/NextIcon';
import PrevIcon from '../Icons/PrevIcon';
import { WEEK_START_DAY_FORMAT } from '../../constants';

dayjs.Ls.en.weekStart = 1;

const nextWeekStartDay = (weekStartDay) => {
  return dayjs(weekStartDay).add(7, 'days').format(WEEK_START_DAY_FORMAT);
};

const prevWeekStartDay = (weekStartDay) => {
  return dayjs(weekStartDay).subtract(7, 'days').format(WEEK_START_DAY_FORMAT);
};

const weekName = (weekStartDay) => {
  const weekEndDay = dayjs(weekStartDay).add(6, 'days');
  const isOneMonth = dayjs(weekStartDay).month() === dayjs(weekEndDay).month();

  return isOneMonth
    ? `${dayjs(weekStartDay).format('MMM D')}-${dayjs(weekEndDay).format('D, YYYY')}`
    : `${dayjs(weekStartDay).format('MMM D')}-${dayjs(weekEndDay).format('MMM D, YYYY')}`;
};

const color = (condition) => condition ? 'gray' : 'white'

const WeekSelector = (props) => {
  const { onChange, week, minDate } = props;

  if (!week) return null;

  const now = dayjs().format(WEEK_START_DAY_FORMAT);
  const nextWeek = nextWeekStartDay(week);

  const disabledNext = () => {
    return now < nextWeek;
  };

  const disabledPrev = () => {
    return week < dayjs(minDate).format(WEEK_START_DAY_FORMAT);
  };

  const handleNext = () => {
    if (disabledNext()) return;
    const nextWeek = nextWeekStartDay(week);
    onChange(nextWeek);
  };

  const handlePrev = () => {
    if (disabledPrev()) return;
    const prevWeek = prevWeekStartDay(week);
    onChange(prevWeek);
  };

  return (
    <Container>
      <PrevIcon onClick={handlePrev} color={color(disabledPrev())} />
      <WeekName>Week {weekName(week)}</WeekName>
      <NextIcon onClick={handleNext} color={color(disabledNext())} />
    </Container>
  );
};

export default WeekSelector;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
`;

const WeekName = styled.div`
  padding: 10px 30px;
  font-size: 20px;
  min-width: 240px;
  text-align: center;
`;
