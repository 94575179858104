import { request } from '../utils/request';

export const postGame = async (params) => {
  try {
    const res = await request(
      '/games',
      {
        method: 'POST',
      },
      { ...params }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getGamesWithLimitOffset = async (limit, offset) => {
  try {
    return await request(
      `/games?limit=${limit}&offset=${offset}`,
    );
  } catch (error) {
    throw error;
  }
};
