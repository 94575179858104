import { request } from '../utils/request';

export const getUser = async (id) => {
  try {
    const res = await request('/users/' + id)
    return res
  } catch (error) {
    throw error
  }
};
