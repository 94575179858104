import React from 'react';
import styled from '@emotion/styled';
import NextIcon from '../Icons/NextIcon';
import PrevIcon from '../Icons/PrevIcon';

const TablePagination = (props) => {
  const { pageIndex, prev, next, hasNext, pageSize, onPageSizeChange } = props;

  return (
    <Container>
      <PrevIcon onClick={pageIndex > 0 ? prev : null} color={pageIndex > 0 ? 'white' : 'gray'} />
      <ItemsCount
        value={pageSize}
        onChange={onPageSizeChange}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </ItemsCount>
      <NextIcon onClick={hasNext ? next : null} color={hasNext ? 'white' : 'gray'} />
    </Container>
  );
};

export default TablePagination;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
`;

const ItemsCount = styled.select`
  padding: 10px 30px;
  font-size: 20px;
  min-width: 240px;
  text-align: center;
  background-color: transparent;
  color: gray;
  border-color: transparent;
  margin-right: 30px;
`;
