import problemSolver from "./mentalMathSolver"
import linear from "./gaussSolver"

export default function prolbemSolver(problem, type, returnAsSrting=true){
  if(type==="Mental math")
    return problemSolver(problem)
  else if(type==="System of equations"){
    let answer=linear.solve(problem[0],problem[1])
    if(returnAsSrting){
      for(let i=0;i<answer.length;i+=1)
        answer[i]=String(answer[i])
    }
    return answer
  }
}