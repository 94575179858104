import React, { useState } from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const JoinLinkWithCopy = ({ joinUrl }) => {

  const [copySuccess, setCopySuccess] = useState(false);

  if (!joinUrl) return null

  return (
    <p className='link'>
    {joinUrl}
    <CopyToClipboard text={joinUrl} onCopy={() => setCopySuccess(true)}>
      <button className='buttonSm cancel'>
        {copySuccess ? 'Coped' : "Copy"}
      </button>
    </CopyToClipboard>
  </p>
  )
}

export default JoinLinkWithCopy
