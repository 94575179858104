import React from 'react';

import JoinLinkWithCopy from './JoinLinkWithCopy';

const GroupListItem = (props) => {
  const { handleGroupDelete, handleGroupLeave, item, onClick } = props;
  const { id, name, description, isOwner, pin_code, joinUrl, users_count, date, school_name } = item;
  const nameTxt = school_name ? `${name}. School: <strong>${school_name}</strong>` : name;

  const handleGroupDetails = () => {
    onClick(id)
  }

  return (
    <tr className='groupRow'>
      <td>
        {school_name ? (
          <>
            <h5>{name}</h5>
            <h6 className='schoolName'>
              School: <strong>{school_name}</strong>
            </h6>
          </>
        ) : (
          <h5>{nameTxt}</h5>
        )}

        <p>{description}</p>
        {isOwner && (
          <p className='pinCode'>
            Pin code is
            <strong>{` ${pin_code}`}</strong>
          </p>
        )}
        <JoinLinkWithCopy joinUrl={joinUrl} />
      </td>
      <td>{users_count || 0}</td>
      <td>{date}</td>
      <td>
        <button className='buttonSm primary' style={{marginRight: '10px'}} onClick={handleGroupDetails}>
          Details
        </button>
        {isOwner && (
          <button className='buttonSm danger' onClick={() => handleGroupDelete(id)}>
            Remove
          </button>
        )}
        {!isOwner && (
          <button className='buttonSm cancel' onClick={() => handleGroupLeave(id)}>
            Leave
          </button>
        )}
      </td>
    </tr>
  );
};

export default GroupListItem;
