/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7847b35f-0869-4d83-94e5-dea6ad72f28c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_KZfa4fO8q",
    "aws_user_pools_web_client_id": "k86b6cqelmijjkm8padleo3jc",
    "oauth": {
        "domain": "mathtrainer20aa70f9-20aa70f9-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://nikiforkiselev.com/",
        "redirectSignOut": "http://localhost:3000/,https://nikiforkiselev.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
