import React from "react";

export default function DivisionIcon({
  color = "black",
  size = 50,
  onClick,
  fieldName,
}) {
  const handleClick = () => {
    onClick(fieldName);
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width={size}
        height={size}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          stroke="null"
          id="svg_1"
          fill={color}
          transform="translate(0,512) scale(0.10000000149011612,-0.10000000149011612) "
        >
          <path
            stroke="null"
            id="svg_2"
            d="m565.394778,5084.741645c-129.710584,-34.448112 -228.978888,-92.744918 -334.865079,-197.414182c-95.297572,-95.394772 -169.417906,-230.537367 -195.889453,-356.405469c-19.853661,-99.369555 -19.853661,-3827.715243 0,-3927.084798c26.471548,-125.868102 100.591881,-261.010697 195.889453,-356.405469c107.209768,-105.994192 205.154495,-162.96607 340.159389,-197.414182c99.268304,-25.17362 149.564245,-26.498548 1990.660391,-26.498548c1838.448991,0 1891.392087,1.324927 1990.660391,26.498548c244.861817,62.271588 423.544764,229.212439 513.548026,476.973862l30.44228,86.120281l0,1954.267907l0,1954.267907l-30.44228,86.120281c-45.001631,123.218248 -111.180501,225.237657 -198.536608,307.383156c-96.621149,88.770135 -185.300834,136.467522 -315.011418,169.590707c-99.268304,25.17362 -150.887822,26.498548 -1995.9547,25.17362c-1825.213217,0 -1898.009974,-1.324927 -1990.660391,-25.17362zm3997.20371,-360.380251c33.089435,-21.198838 82.061798,-60.94666 108.533346,-91.41999c93.973995,-109.968974 87.356108,30.47333 87.356108,-2065.561808c0,-2096.035138 6.617887,-1955.592834 -87.356108,-2065.561808c-26.471548,-30.47333 -75.443911,-70.221152 -108.533346,-91.41999l-62.208137,-35.77304l-1919.187212,-3.974782c-1703.444098,-2.649855 -1925.805099,-1.324927 -1973.453885,17.224056c-120.445542,45.047531 -203.830918,140.442304 -236.920352,267.635334c-17.206506,70.221152 -19.853661,304.733301 -17.206506,1946.318343l3.970732,1866.822699l37.060167,75.520861c43.678054,87.445208 132.357739,172.240561 211.772382,201.388964c48.972363,17.224056 305.746377,18.548984 1974.777462,17.224056l1919.187212,-2.649855l62.208137,-35.77304z"
          />
          <path
            stroke="null"
            id="svg_3"
            d="m2466.051907,4068.522334c-66.178869,-42.397677 -76.767488,-87.445208 -76.767488,-312.682865c0,-190.789545 1.323577,-206.688674 30.44228,-249.08635c34.413012,-51.672168 112.504078,-83.470426 170.741483,-71.546079c55.59025,13.249274 113.827655,67.571297 129.710584,123.218248c15.882929,60.94666 17.206506,335.206631 0,405.427783c-17.206506,79.495644 -63.531715,117.918538 -148.240667,123.218248c-50.295941,3.974782 -80.738221,-1.324927 -105.886191,-18.548984z"
          />
          <path
            stroke="null"
            id="svg_4"
            d="m1158.357448,2730.345665c-84.708953,-26.498548 -119.121965,-74.195934 -119.121965,-161.641142c0,-67.571297 21.177238,-113.943756 64.855292,-143.092159c31.765857,-22.523766 121.76912,-23.848693 1433.434311,-27.823475c1544.614811,-3.974782 1466.523745,-7.949564 1519.466841,79.495644c33.089435,55.646951 33.089435,124.543175 0,180.190126c-52.943096,86.120281 21.177238,82.145498 -1498.289603,80.820571c-759.73342,0 -1389.756257,-3.974782 -1400.344876,-7.949564z"
          />
          <path
            stroke="null"
            id="svg_5"
            d="m2485.905567,1687.627806c-83.385375,-37.097967 -96.621149,-82.145498 -96.621149,-310.03301c0,-230.537367 11.912196,-275.584898 84.708953,-314.007793c31.765857,-17.224056 60.88456,-21.198838 108.533346,-15.899129c80.738221,9.274492 131.034161,59.621733 144.269935,147.066941c14.559351,92.744918 1.323577,380.254162 -19.853661,421.326911c-34.413012,72.871007 -141.62278,107.319119 -221.037424,71.546079z"
          />
        </g>
      </svg>
    </div>
  );
}
