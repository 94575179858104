import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';

import { joinGroup, getAvailableGroups } from '../actions';

const formatData = (items) => {
  return items.map((item) => ({
    ...item,
    label: item.name,
  }));
};

const onSearch = ({ state, methods }) =>
  methods.sortBy().filter((item) => {
    return item.label.toLowerCase().includes(state.search) || item.description.toLowerCase().includes(state.search);
  });

const NewGroup = (props) => {
  const { history, user } = props;
  const [values, setValues] = useState([]);
  const [pinCode, setPinCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    setLoading(true);

    if (user) {
      getAvailableGroups()
        .then((result) => {
          result && setData(formatData(result));
        })
        .catch((error) => {
          console.log('🚀 ~ file: JoinGroup.jsx ~ line 31 ~ fetchData ~ error', error);
        });

      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCancel = () => {
    history.goBack();
  };
  const onChange = (values) => {
    setValues(values);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (pinCode.length !== 4) return null
    try {
      setLoading(true);
      setError(null);
      await joinGroup(values[0].id, pinCode);
      setLoading(false);
      history.goBack();
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <div className='block' style={{ minHeight: '50vh' }}>
      <div className='row' style={{ maxHeight: '50px' }}>
        <h1>Join Group</h1>
      </div>
      {loading ? (
        <div>Loading ...</div>
      ) : (
        <form onSubmit={handleSubmit}>
          <StyledSelect
            searchBy='description'
            valueField='id'
            sortBy='label'
            required
            color='#fff'
            values={values}
            clearable
            placeholder='Start typing'
            options={data}
            searchFn={onSearch}
            onChange={onChange}
            multi={false}
            itemRenderer={({ item, methods }) => (
              <StyledItem key={item.id} onClick={() => methods.addItem(item)}>
                <div>{item.label}</div>
                <div>{item.description}</div>
              </StyledItem>
            )}
          />
          <label htmlFor='pinCode' className='input'>
            <input required id='pinCode' placeholder='&nbsp;' value={pinCode} onChange={(e) => setPinCode(e.target.value)} />
            <span className='label'>Pin Code</span>
            <span className='focus-bg'></span>
          </label>
          <br/>
          <br/>
          <div>
            <button className='buttonSm cancel' onClick={handleCancel}>
              Cancel
            </button>
            <button className='buttonSm success' style={{ marginLeft: 20 }}>
              Join group
            </button>
          </div>
        </form>
      )}
      {error && <ErrorBlock>Error: {error}</ErrorBlock>}
    </div>
  );
};

const StyledSelect = styled(Select)`
  background: var(--gray);
  border: #333 !important;
  width: 350px;
  color: #fff;
  margin: 20px 0;
  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: #fff;
    background: var(--gray);
    font-size: 32px;
    line-height: 56px;
  }
  .react-dropdown-select-dropdown-handle > svg {
    width: 24px;
    height: 24px;
    margin-top: -6px;
  }
  .react-dropdown-select-option {
    border: 1px solid #fff;
  }
  .react-dropdown-select-content > span {
    padding: 10px 8px;
    font-size: 24px;
  }
  .react-dropdown-select-item {
    color: #333;
    flex: 1;
    padding: 8px;
  }
  .react-dropdown-select-input {
    color: #fff;
    background: var(--gray);
    height: 56px;
    font-size: 24px;
  }
  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    width: 350px;
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 300px;
    overflow: auto;
    z-index: 9;
    box-shadow: none;
    color: #fff !important;
  }
  .react-dropdown-select-item {
    color: #f2f2f2;
    border-bottom: 1px solid #333;

    :hover {
      color: #ffffff80;
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    border-bottom: 1px solid #333;
    color: #fff;
    font-weight: bold;
  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #777;
    color: #ccc;
  }
`;

const ErrorBlock = styled.p`
  color: red;
  margin: 20px 0;
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 10px;
  }
  :hover {
    background: var(--gray);
    color: white;
  }
`;

export default withRouter(NewGroup);
