import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as dayjs from 'dayjs';

import { getGroups, removeGroup, leaveGroup } from '../actions';
import { groupJoinLink } from '../../../helpers/url';
import GroupListItem from './GroupListItem';

const formatData = (items) => {
  return items.map((item) => ({
    ...item,
    date: dayjs(item.createdAt).format('H:mm, D MMMM, YY'),
    joinUrl: groupJoinLink(item.group_hash, item.name, item.owner_name),
  }));
};

const Groups = (props) => {
  const { history, user } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    if (user) {
      getGroups()
        .then((result) => {
          result && setData(formatData(result));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('🚀 ~ file: Groups.jsx ~ line 21 ~ fetchData ~ error', error);
        });
    }
  }, [user]);

  const handleNewGroup = () => {
    history.push({
      pathname: '/profile/groups/new',
    });
  };

  const handleJoinGroup = () => {
    history.push({
      pathname: '/profile/groups/join',
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleGroupDelete = async (groupId) => {
    const group = data.find((item) => item.id === groupId);
    const result = window.confirm(`Are you sure want to delete ${group.name} group?`);
    if (result) {
      const groupsNew = await removeGroup(groupId);
      groupsNew && setData(formatData(groupsNew, user));
    }
  };

  const handleGroupLeave = async (groupId) => {
    const group = data.find((item) => item.id === groupId);
    const result = window.confirm(`Are you sure want to leave ${group.name} group?`);
    if (result) {
      const groupsNew = await leaveGroup(groupId);
      groupsNew && setData(formatData(groupsNew, user));
    }
  };

  const handleGroupClick = (groupId) => {
    history.push({
      pathname: `/profile/groups/${groupId}`,
    });
  }

  return (
    <div className='block' style={{ minHeight: '50vh' }}>
      <div className='row'>
        <h1>Groups</h1>
        <div>
          <button className='buttonSm primary' onClick={handleJoinGroup}>
            Join group
          </button>
          <button className='buttonSm success left-margin' onClick={handleNewGroup}>
            Create new group
          </button>
        </div>
      </div>
      {loading ? <div>Loading ...</div> : null}
      {!loading && data && data.length ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Users</th>
              <th>Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <GroupListItem  onClick={handleGroupClick} key={`${item.id}`} item={item} handleGroupDelete={handleGroupDelete} handleGroupLeave={handleGroupLeave} />
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default withRouter(Groups);
