import React from 'react'

const UserInfo = ({ user }) => {

  return (
    <div className="block" style={{ minHeight: '50vh'}}>
      <h1>
        Your profile
      </h1>
      <p>
        {`Email ${user && user.attributes.email}`}
      </p>
    </div>
  )
}

export default UserInfo
