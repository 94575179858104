import React, { useContext, useEffect } from 'react';
import { useParams, useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { joinGroupByHash } from './actions';
import { RedirectContext } from '../../state/redirect.state';

import { Auth } from 'aws-amplify';
import GoogleButton from 'react-google-button';

const JoinGroupByLink = (props) => {
  const { user, history } = props;
  let { groupHash } = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { title, owner } = parsed;
  const { setRedirectUrl } = useContext(RedirectContext);

  useEffect(() => {
    const redirectUrl = user ? null : window.location.pathname + window.location.search;
    setRedirectUrl(redirectUrl);
  }, []);

  const handleCancel = () => {
    setRedirectUrl(null);
    history.push('/');
  };

  const handleSubmit = async () => {
    if (!groupHash || groupHash.length < 0) return null;
    try {
      await joinGroupByHash(groupHash);
      setRedirectUrl(null);
      history.push('/profile/groups');
    } catch (error) {}
  };

  return (
    <div className='joinByLinkContainer'>
      <h2>
        Would you like to join to <strong>{title}</strong> group, created by <strong>{owner}</strong>?
      </h2>
      {!user ? (
        <>
          <h4>You need to be authorized with Google to join group.</h4>
          <GoogleButton onClick={() => Auth.federatedSignIn({ provider: 'Google' })} />
        </>
      ) : null}
      <br />
      <br />
      <div className='row' style={{ justifyContent: 'flex-start' }}>
        <button className='buttonSm cancel' onClick={handleCancel}>
          Cancel
        </button>
        <button className='buttonSm success left-margin' disabled={!user} onClick={handleSubmit}>
          Join
        </button>
      </div>
    </div>
  );
};

export default withRouter(JoinGroupByLink);
