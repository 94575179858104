import { getUser } from '../../api/users';
import * as apiGroups from '../../api/group';
import * as apiGames from '../../api/game';
import * as dayjs from 'dayjs';
import { WEEK_START_DAY_FORMAT } from '../../constants';
export const findOrCreateUser = async (user) => {
  try {
    const userId = user.attributes.sub;
    return await getUser(userId);
  } catch (error) {
    throw(error)
  }
};

export const createGroup = async (params) => {
  try {
    return await apiGroups.createGroup(params);
  } catch (error) {
    throw(error)
  }
};

export const removeGroup = async (groupId) => {
  try {
    return await apiGroups.removeGroup(groupId);
  } catch (error) {
    throw(error)
  }
};

export const leaveGroup = async (groupId) => {
  try {
    return await apiGroups.leaveGroup(groupId);
  } catch (error) {
    throw(error)
  }
};

export const getGames = async (limit, offset) => {
  try {
    return await apiGames.getGamesWithLimitOffset(limit, offset);
  } catch (error) {
    console.log("🚀 ~ file: actions.js ~ line 41 ~ getGames ~ error", error)
    throw(error)
  }
};

export const getGroups = async () => {
  try {
    return await apiGroups.getGroups();
  } catch (error) {
    throw(error)
  }
};

export const getAvailableGroups= async () => {
  try {
    return await apiGroups.getAvailable();
  } catch (error) {
    throw(error)
  }
};

export const joinGroup = async (groupId, pinCode) => {
  try {
    return await apiGroups.joinGroup(groupId, pinCode);
  } catch (error) {
    throw(error)
  }
};

export const getGroupDetails = async (params) => {
  const { start, end } = params
  // Request for extra days because of the timezone converting
  const startMinusOneDay = dayjs(start).subtract(1, 'day').format(WEEK_START_DAY_FORMAT)
  const endPlusOneDay = dayjs(end).add(1, 'day').format(WEEK_START_DAY_FORMAT)
  const newParams = {
    ...params,
    start: startMinusOneDay,
    ens: endPlusOneDay,
  }
  try {
    return await apiGroups.getGroup(newParams);
  } catch (error) {
    console.log("🚀 ~ file: actions.js ~ line 85 ~ getGroupDetails ~ error", error)
    throw(error)
  }
};