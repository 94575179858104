import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { createGroup } from '../actions';
import { nsOpenDataRequest } from '../../../utils/request';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';

const clearData = (arr) =>
  [...new Map(arr.map((item) => [item.school_name, item])).values()].map((item) => ({ id: item.school_code, label: item.school_name }));

const NewGroup = (props) => {
  const { history } = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    nsOpenDataRequest()
      .then((data) => {
        setSchools(clearData(data));
      })
      .catch((error) => {
        console.log('🚀 ~ file: NewGroup.jsx ~ line 17 ~ useEffect ~ error', error);
      });
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name.length < 3 || description.length < 3) return null;
    try {
      const params = { name, description };
      if (selectedSchool.length > 0) {
        params.schoolCode = selectedSchool[0].id;
        params.schoolName = selectedSchool[0].label;
      }
      await createGroup(params);
      history.goBack();
    } catch (error) {
      setError(error)
    }
  };

  const onChange = (values) => {
    setSelectedSchool(values);
  };

  return (
    <div className='block' style={{ minHeight: '50vh' }}>
      <h1>Create New Group</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor='name' className='input'>
          <input id='name' required placeholder='&nbsp;' value={name} onChange={(e) => setName(e.target.value)} />
          <span className='label'>Name</span>
          <span className='focus-bg'></span>
        </label>
        <br />
        <label htmlFor='description' className='input'>
          <textarea rows={4} required id='description' placeholder='&nbsp;' value={description} onChange={(e) => setDescription(e.target.value)} />
          <span className='label'>Description</span>
          <span className='focus-bg'></span>
        </label>
        <StyledSelect
          searchBy='label'
          valueField='label'
          sortBy='label'
          required={false}
          color='#fff'
          values={selectedSchool}
          clearable
          placeholder='Select School in NS'
          options={schools}
          onChange={onChange}
          multi={false}
          itemRenderer={({ item, methods }) => (
            <StyledItem key={item.id} onClick={() => methods.addItem(item)}>
              <div>{item.label}</div>
            </StyledItem>
          )}
        />
        <br />
        {error && <ErrorBlock>Error: {error}</ErrorBlock>}
        <div className='row' style={{ maxWidth: '350px' }}>
          <button className='buttonSm cancel' onClick={handleCancel}>
            Cancel
          </button>
          <button className='buttonSm success left-margin'>Create</button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(NewGroup);

const StyledSelect = styled(Select)`
  background: var(--gray);
  border: #333 !important;
  width: 350px;
  color: black;
  margin: -5px 0;
  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: black;
    background: var(--gray);
    font-size: 32px;
    line-height: 56px;
  }
  .react-dropdown-select-dropdown-handle > svg {
    width: 24px;
    height: 24px;
    margin-top: -6px;
  }
  .react-dropdown-select-option {
    border: 1px solid #fff;
  }
  .react-dropdown-select-content > span {
    padding: 14px 8px;
    font-size: 18px;
  }
  .react-dropdown-select-item {
    color: #333;
    flex: 1;
    padding: 8px;
  }
  .react-dropdown-select-input {
    color: #fff;
    background: var(--gray);
    height: 56px;
    font-size: 18px;
    border-radius: 3px;
  }
  .react-dropdown-select-input::placeholder {
    color: black;
    font-size: 18px;
  }
  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    width: 350px;
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 300px;
    overflow: auto;
    z-index: 9;
    box-shadow: none;
    color: black !important;
  }
  .react-dropdown-select-item {
    color: #f2f2f2;
    border-bottom: 1px solid #333;

    :hover {
      color: #ffffff80;
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    border-bottom: 1px solid #333;
    color: #fff;
    font-weight: bold;
  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #777;
    color: #ccc;
  }
`;

const ErrorBlock = styled.p`
  color: red;
  margin: 20px 0;
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 10px;
  }
  :hover {
    background: var(--gray);
    color: white;
  }
`;
