import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WelcomeScreen from "./containers/WelcomeScreen";
import Login from "./containers/Login";
import HomeScreen from "./containers/HomeScreen";
import Game from "./containers/Game";
import useAmplifyAuth from "./helpers/useAmplifyAuth";
import configureAmplify from "./helpers/configureAmplify";
import NavBar from "./containers/NavBar";
import Profile from "./containers/Profile";
import JoinGroupByLink from "./containers/JoinGroupByLink";
import { RedirectProvider } from "./state/redirect.state";
import UnitCircle from "./components/unitCircle/unitCircle";

import "./index.css";

configureAmplify();

export const UserContext = React.createContext(null);

function App() {
  const {
    state: { user },
    handleSignout: handleSignOut,
  } = useAmplifyAuth();

  return (
    <React.StrictMode>
      <RedirectProvider>
        <UserContext.Provider value={{ user, handleSignOut }}>
          <Router>
            <NavBar user={user} />
            <div className="container">
              <Switch>
                <Route exact path="/">
                  <HomeScreen user={user} />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/game">
                  <Game user={user} />
                </Route>
                <Route path="/about" handleSignOut={handleSignOut}>
                  <WelcomeScreen user={user} />
                </Route>
                <Route path="/join/:groupHash" handleSignOut={handleSignOut}>
                  <JoinGroupByLink user={user} />
                </Route>
              </Switch>
            </div>
            <Route path="/profile">
              <Profile user={user} handleSignOut={handleSignOut} />
            </Route>
            <Route path="/unitcircle">
              <UnitCircle />
            </Route>
          </Router>
        </UserContext.Provider>
      </RedirectProvider>
    </React.StrictMode>
  );
}

export default App;
