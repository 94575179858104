import React from "react";

export default function MinusIcon({
  color = "black",
  size = 50,
  onClick,
  fieldName,
}) {
  const handleClick = () => {
    onClick(fieldName);
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width={size}
        height={size}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          stroke="null"
          id="svg_1"
          fill={color}
          transform="translate(0,512) scale(0.10000000149011612,-0.10000000149011612) "
        >
          <path
            stroke="null"
            id="svg_2"
            d="m655.509857,5101.043145c-240.754135,-23.9424 -457.565871,-174.247468 -563.976538,-391.059203c-83.798401,-171.587201 -81.138134,-81.138134 -77.147734,-2197.380286l3.9904,-1924.70295l30.593067,-86.458667c63.846401,-178.237868 190.209068,-327.212803 347.164803,-411.011204c162.276268,-85.128534 42.564267,-81.138134 2172.107752,-81.138134c1741.144548,0 1941.994683,2.660267 2007.171217,21.282134c235.433602,70.497067 411.011204,234.103469 502.790404,468.206937l34.583467,89.118934l3.9904,1924.70295c2.660267,1653.355748 0,1938.004283 -15.9616,2019.142417c-54.535467,260.706136 -246.074669,469.537071 -504.120538,548.014938c-89.118934,26.602667 -110.401068,26.602667 -1968.59735,29.262934c-1033.513609,0 -1920.71255,-2.660267 -1972.58775,-7.9808zm3917.242701,-376.427737c38.573867,-21.282134 90.449067,-62.516267 115.721601,-90.449067c90.449067,-107.740801 85.128534,29.262934 85.128534,-2075.008018c0,-1375.357879 -3.9904,-1915.392017 -14.631467,-1951.305617c-25.272534,-85.128534 -103.750401,-174.247468 -191.539202,-219.472002l-78.477867,-39.904l-1923.372817,0l-1923.372817,0l-78.477867,39.904c-87.788801,45.224534 -166.266668,134.343468 -191.539202,219.472002c-10.641067,35.9136 -14.631467,577.277872 -14.631467,1951.305617c0,2075.008018 -3.9904,1959.286417 74.487467,2061.706684c39.904,51.8752 115.721601,105.080534 187.548802,131.683201c42.564267,14.631467 377.75787,17.291733 1965.937084,14.631467l1915.392017,-2.660267l71.827201,-39.904z"
          />
          <path
            stroke="null"
            id="svg_3"
            d="m1109.075727,2702.482591c-71.827201,-38.573867 -98.429868,-150.305068 -57.195734,-231.443202c43.8944,-83.798401 -39.904,-79.808001 1509.701346,-79.808001c1549.605347,0 1465.806946,-3.9904 1509.701346,79.808001c25.272534,49.214934 25.272534,123.702401 0,172.917335c-43.8944,83.798401 39.904,79.808001 -1512.361613,79.808001c-1225.052811,-1.330133 -1419.252279,-3.9904 -1449.845346,-21.282134z"
          />
        </g>
      </svg>
    </div>
  );
}
