import React, { useRef, useEffect } from "react";
import "../../../index.css";
import PlusIcon from "./icons/PlusIcon";
import MinusIcon from "./icons/MinusIcon";
import MultiplyIcon from "./icons/MultiplyIcon";
import DivisionIcon from "./icons/DivisionIcon";
import NumberInCircle from "../../NumberInCircle";
import WithSizes from "react-sizes";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const GRAY = "#707070";
const SELECTED_ICON_COLOR = "var(--green)";
const UNSELECTED_ICON_COLOR = GRAY;

const MAX_NUMBERS_VALUES = [10, 20, 50, 100];

function MentalMathForm(props){
    const { t, handleChange, handleClick, values, isMobile } = props;
    
    const ICON_SIZE = isMobile ? 50 : 75;

    const handleChangeNumberOfSigns = (e) => {
        let value = e;
        handleChange(value, "numberOfSigns");
    };

    const handleChangeMaxNumbers = (value) => {
        handleChange(value, "maxNumbers");
    };

    const handleIconClick = (filedName) => {
        let value = values[filedName] ? 0 : 1;
        handleChange(value, filedName);
    };    

    return(
        <div>
            <div className="block-row">
                <div className="block">
                <h4>{t("Actions")}</h4>
                <div className="rowBlock">
                    <PlusIcon
                    size={ICON_SIZE}
                    color={values.sign1 ? SELECTED_ICON_COLOR : UNSELECTED_ICON_COLOR}
                    onClick={handleIconClick}
                    fieldName="sign1"
                    />
                    <MinusIcon
                    size={ICON_SIZE}
                    color={values.sign2 ? SELECTED_ICON_COLOR : UNSELECTED_ICON_COLOR}
                    onClick={handleIconClick}
                    fieldName="sign2"
                    />
                    <MultiplyIcon
                    size={ICON_SIZE}
                    color={values.sign3 ? SELECTED_ICON_COLOR : UNSELECTED_ICON_COLOR}
                    onClick={handleIconClick}
                    fieldName="sign3"
                    />
                    <DivisionIcon
                    size={ICON_SIZE}
                    color={values.sign4 ? SELECTED_ICON_COLOR : UNSELECTED_ICON_COLOR}
                    onClick={handleIconClick}
                    fieldName="sign4"
                    />
                </div>
                <p className="explanation">{t("Actions_explanation")}</p>
                </div>
            </div>
            <div className="block-row">
                <div className="block">
                <h4>{t("Max_value")}</h4>
                <div className="rowBlock">
                    {MAX_NUMBERS_VALUES.map((item) => (
                    <NumberInCircle
                        key={`${item}`}
                        value={item}
                        handleClick={handleChangeMaxNumbers}
                        color={
                        item === values.maxNumbers ? "var(--green)" : "var(--gray)"
                        }
                    />
                    ))}
                </div>
                <p className="explanation">{t("Max_value_explanation")}</p>
                </div>
            </div>
            <div className="block-row">
                <div className="block">
                <h4>{t("Number_of_signs")}</h4>
                <div className="rowBlock" style={{ justifyContent: "space-between" }}>
                    {[1, 2, 3].map((item) => (
                    <NumberInCircle
                        key={item}
                        value={item}
                        handleClick={handleChangeNumberOfSigns}
                        color={
                        item === values.numberOfSigns ? "var(--green)" : "var(--gray)"
                        }
                    />
                    ))}
                </div>
                <p className="explanation">{t("Number_of_signs_explanation")}</p>
                </div>
            </div>
        </div>
    );
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 480,
});

export default compose(withTranslation(), WithSizes(mapSizesToProps))(MentalMathForm);