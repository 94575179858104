import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import generator from "../../utils/generator";
import problemSolver from "../../utils/problemSolver";
import Problem from "../../containers/Problem";
import Results from "../../components/Results";
import { saveGame } from "./actions";
import gameScorer from "../../utils/gameScorer";
import checkAnswer from "../../utils/checkAnswer/checkAnswer"

let rightAnswersCount = 0;
let rightAnswers = [];
let userAnswers = [];

function Game(props) {
  const { history, location, user } = props;
  const conditions = location.state.conditions;
  const [problemIndex, setProblemIndex] = useState(0);
  const [problems, setProblems] = useState([]);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    let problems = [];
    for (let i = 0; i < conditions.numberOfProblems; i++) {
      problems.push(generator(conditions));
    }
    setProblems(problems);
    setProblemIndex(0);
    rightAnswersCount = 0;
    rightAnswers = [];
    userAnswers = [];
  }, [conditions]);

  const score = () => {
    const problemsCount = problems.length;
    return gameScorer({
      rightAnswersCount,
      problemsCount,
      conditions,
    });
  };

  const saveResults = async () => {
    if (!user) return null;
    const problemsCount = problems.length;
    try {
      const problemsArr = problems.map((item, index) => ({
        question: item,
        answer: userAnswers[index],
        result: userAnswers[index] === rightAnswers[index],
      }));

      await saveGame({
        rightAnswersCount,
        problemsCount,
        score: score(),
        problemsArr,
      });
    } catch (error) {
      console.log(
        "🚀 ~ file: index.js ~ line 106 ~ saveResults ~ error",
        error
      );
    }
  };

  const onCheck = (userInput) => {
    let answer = problemSolver(problems[problemIndex], conditions["problemType"]);
    console.log("answer: " + answer)
    rightAnswers.push(answer);
    userAnswers.push(userInput);
    let result = checkAnswer(userInput,answer)
    if (result) {
      rightAnswersCount++;
    }
    if (problemIndex === conditions.numberOfProblems - 1) {
      saveResults();
      setIsFinished(true);
    }
    setProblemIndex((problemIndex) => problemIndex + 1);
  };

  const newGame = () => {
    history.push({
      pathname: "/",
    });
  };

  return (
    <div>
      {isFinished ? (
        <Results
          conditions={{
            problemType: conditions.problemType,
            totalNumberOfProblems: conditions.numberOfProblems,
            problems,
            userAnswers,
            rightAnswers,
          }}
          score={score()}
          handleClick={newGame}
          user={user}
        />
      ) : (
        <Problem
          currentNumberOfProblems={problemIndex}
          problem={problems[problemIndex]}
          onCheck={onCheck}
          rightAnswers={rightAnswersCount}
          totalNumberOfProblems={conditions.numberOfProblems}
          problemType={conditions.problemType}
        />
      )}
    </div>
  );
}

export default withRouter(Game);
