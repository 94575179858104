import React, { useRef, useEffect, useState } from "react";
import "../../../index.css";
import WithSizes from "react-sizes";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';

const problemTypes = [{id: 1, label: "Mental math"}, 
                    {id: 2, label: "System of equations"}, 
                    {id: 3, label: "Trigonometry"},
];

function ProblemTypeSeleciton(props){
    const { t, handleChange, handleClick, values, isMobile } = props;

    const onChange = (values) => {
        handleChange(values[0].label, "problemType");
    };

    return (
        <div className="block">
            <h4>{t("ProblemType")}</h4>
            <StyledSelect
                searchBy='label'
                valueField='label'
                sortBy='label'
                required={false}
                color='#fff'
                values={values["problemType"]}
                placeholder='Select problem type'
                options={problemTypes}
                onChange={onChange}
                multi={false}
                itemRenderer={({ item, methods }) => (
                    <StyledItem key={item.id} onClick={() => methods.addItem(item)}>
                    <div>{item.label}</div>
                    </StyledItem>
                )}
            />
        </div>

    );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
});

export default compose(withTranslation(), WithSizes(mapSizesToProps))(ProblemTypeSeleciton);

const StyledSelect = styled(Select)`
  background: var(--gray);
  border: #333 !important;
  width: 350px;
  color: black;
  margin: -5px 0;
  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: black;
    background: var(--gray);
    font-size: 32px;
    line-height: 56px;
  }
  .react-dropdown-select-dropdown-handle > svg {
    width: 24px;
    height: 24px;
    margin-top: -6px;
  }
  .react-dropdown-select-option {
    border: 1px solid #fff;
  }
  .react-dropdown-select-content > span {
    padding: 14px 8px;
    font-size: 18px;
  }
  .react-dropdown-select-item {
    color: #333;
    flex: 1;
    padding: 8px;
  }
  .react-dropdown-select-input {
    color: #fff;
    background: var(--gray);
    height: 56px;
    font-size: 18px;
    border-radius: 3px;
  }
  .react-dropdown-select-input::placeholder {
    color: black;
    font-size: 18px;
  }
  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    width: 350px;
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 300px;
    overflow: auto;
    z-index: 9;
    box-shadow: none;
    color: black !important;
  }
  .react-dropdown-select-item {
    color: #f2f2f2;
    border-bottom: 1px solid #333;

    :hover {
      color: #ffffff80;
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    border-bottom: 1px solid #333;
    color: #fff;
    font-weight: bold;
  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #777;
    color: #ccc;
  }
`;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 10px;
  }
  :hover {
    background: var(--gray);
    color: white;
  }
`;
