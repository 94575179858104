import React from 'react';
import * as dayjs from 'dayjs';
import styled from '@emotion/styled';

var updateLocale = require('dayjs/plugin/updateLocale');
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

dayjs.updateLocale('en', {
  weekStart: 1,
});
export const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const formatGames = (games) => {
  return WEEK_DAYS.map((weekDay) => {
    const arr = games.filter((item) => item && dayjs(item.date).format('ddd') === weekDay);
    if (arr && arr.length) {
      return {
        key: weekDay,
        score: arr.reduce((acc, item) => {
          acc += item.score;
          return acc;
        }, 0),
        count: arr.length,
      };
    }
    return null;
  });
};

const UserListItem = (props) => {
  const { user, index } = props;
  const { email, games, joinedAt } = user;
  const data = formatGames(games);
  const { totalCount, totalScore } = data.reduce(
    (acc, item) => {
      if (!item) return acc;
      acc.totalCount += item.count;
      acc.totalScore += item.score;
      return acc;
    },
    { totalCount: 0, totalScore: 0 }
  );
  return (
    <Row className='groupRow'>
      <td>{index + 1}</td>
      <td>
        {email}
        <br />
        <small>joined - {dayjs(joinedAt).format('LLLL')}</small>
      </td>
      {data.map((item, index) => (
        <Column key={`${index}`}>
          <DayItem>
            {item ? (
              <SuccessDay>
                {item.count} / {item.score}
              </SuccessDay>
            ) : (
              <FailedDay>0</FailedDay>
            )}
          </DayItem>
        </Column>
      ))}
      <Column>
        <DayItem>
          {totalCount ? (
            <SuccessDay>
              {totalCount} / {totalScore}
            </SuccessDay>
          ) : (
            <FailedDay>0</FailedDay>
          )}
        </DayItem>
      </Column>
    </Row>
  );
};

export default UserListItem;

const Row = styled.tr`
  margin: 10px 0;
`;

const Column = styled.td`
  text-align: center;
`;

const DayItem = styled.div`
  font-size: 20px;
  margin: 10px 0;
`;

const FailedDay = styled.p`
  color: var(--red);
`;

const SuccessDay = styled.p`
  color: var(--green);
`;
