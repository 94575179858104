import React, { useRef, useEffect } from "react";
import "../../index.css";
import NumberInCircle from "../../components/NumberInCircle";
import WithSizes from "react-sizes";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import ProblemTypeSelection from "./ProblemTypeSelection";
import MentalMathForm from "./MentalMathForm";

const NUMBER_OF_PROBLEMS_VALUES = [10, 20, 30];

function Form(props) {
  const { t, handleChange, handleClick, values, isMobile } = props;
  const buttonRef = useRef(null);

  useEffect(() => {
    !isMobile && buttonRef.current && buttonRef.current.focus();
  }, [isMobile]);

  const handleChangeNumberOfProblems = (value) => {
    handleChange(value, "numberOfProblems");
  };

  return (
    <div>
      <div className="circle" />
      <div className="circle2" />
      <div className="block-row">
        <ProblemTypeSelection {...props}/>
      </div> 
      {values.problemType==="Mental math"? <MentalMathForm {...props}/>:null}
      <div className="block-row">
        <div className="block">
          <h4>{t("Number_of_problems")}</h4>
          <div className="rowBlock" style={{ justifyContent: "space-between" }}>
            {NUMBER_OF_PROBLEMS_VALUES.map((item) => (
              <NumberInCircle
                key={`${item}`}
                value={item}
                handleClick={handleChangeNumberOfProblems}
                color={
                  item === values.numberOfProblems
                    ? "var(--green)"
                    : "var(--gray)"
                }
              />
            ))}
          </div>
          <p className="explanation">{t("Number_of_problems_explanation")}</p>
        </div>
      </div>
      <div className="block-row">
        <button ref={buttonRef} onClick={handleClick} className="button">
          {t("Start")}
        </button>
      </div>
    </div>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
});

export default compose(withTranslation(), WithSizes(mapSizesToProps))(Form);