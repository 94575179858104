import React from "react";

export default function MultiplyIcon({
  color = "black",
  size = 50,
  onClick,
  fieldName,
}) {
  const handleClick = () => {
    onClick(fieldName);
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width={size}
        height={size}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          stroke="null"
          id="svg_1"
          fill={color}
          transform="rotate(-180 255.4976959228516,255.2981262207031) translate(0,512) scale(0.10000000149011612,-0.10000000149011612) "
        >
          <path
            stroke="null"
            id="svg_2"
            d="m625.856164,5097.973294c-280.048734,-37.162865 -521.607357,-257.485566 -594.605842,-540.18879c-18.581433,-71.67124 -21.235923,-297.302921 -21.235923,-1990.867776c0,-2103.683616 -5.308981,-1977.595324 83.616447,-2152.791688c55.744298,-114.143086 176.523609,-233.595152 295.975676,-295.975676c180.505345,-95.561653 54.417053,-90.252672 2163.409649,-90.252672c1848.852541,0 1901.942348,1.327245 2001.485737,26.544904c139.360744,35.83562 224.304436,83.616447 327.82956,185.814326c92.907163,91.579918 151.305951,189.796061 193.777797,326.502315c22.563168,74.32573 23.890413,173.869119 23.890413,2002.812982c0,1830.271108 -1.327245,1928.487252 -23.890413,2004.140227c-76.980221,248.194849 -248.194849,422.063968 -500.371434,507.00766c-69.01675,22.563168 -165.905648,23.890413 -1970.959098,25.217658c-1043.214714,1.327245 -1933.796233,-2.65449 -1978.922569,-7.963471zm3856.974504,-345.083748c119.452067,-31.853884 233.595152,-147.324215 265.449037,-266.776282c9.290716,-31.853884 11.945207,-726.003115 10.617961,-1945.741439l-3.981736,-1895.306122l-35.83562,-67.689504c-65.035014,-123.433802 -156.614932,-184.487081 -305.266392,-204.395758c-58.398788,-6.636226 -834.837221,-10.617961 -1928.487252,-7.963471c-1499.787058,2.65449 -1839.561825,6.636226 -1878.051935,21.235923c-128.742783,49.108072 -225.631681,159.269422 -252.176585,282.703224c-9.290716,47.780827 -13.272452,651.677385 -10.617961,1947.068685c3.981736,1749.309152 5.308981,1882.033671 26.544904,1928.487252c39.817356,87.598182 100.870634,151.305951 180.505345,191.123306l75.652975,37.162865l1904.596839,-3.981736c1142.758103,-1.327245 1923.178271,-7.963471 1951.05042,-15.926942z"
          />
          <path
            stroke="null"
            id="svg_3"
            d="m1515.110438,3652.603289c-54.417053,-33.18113 -90.252672,-108.834105 -78.307466,-171.214629c6.636226,-42.471846 66.362259,-107.50686 452.590608,-487.098982l444.627137,-437.990911l-434.009175,-424.718459c-270.758017,-265.449037 -440.645401,-440.645401 -452.590608,-468.51755c-50.435317,-119.452067 76.980221,-256.15832 201.741268,-215.01372c34.508375,10.617961 159.269422,131.397273 473.826531,452.590608l428.700194,436.663665l444.627137,-443.299891c485.771737,-484.444492 485.771737,-484.444492 587.969616,-440.645401c62.380524,25.217658 102.197879,90.252672 102.197879,167.232893l0,59.726033l-447.281627,439.318156l-447.281627,437.990911l382.246613,376.937632c209.704739,207.050249 392.864574,384.901103 407.464271,395.519065c57.071543,39.817356 104.85237,123.433802 104.85237,184.487081c0,114.143086 -132.724518,199.086778 -230.940662,147.324215c-19.908678,-10.617961 -229.613417,-208.377494 -467.190305,-441.972646l-432.68193,-422.063968l-426.045704,424.718459c-234.922398,234.922398 -440.645401,432.68193 -456.572343,440.645401c-43.799091,22.563168 -111.488595,17.254187 -157.942177,-10.617961z"
          />
        </g>
      </svg>
    </div>
  );
}
