import React from "react";
import Navbar, { NavbarText } from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import ChangeLanguage from "../../components/ChangeLanguage";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NavBar = (props) => {
  const { t, user } = props;
  const email = user && user.attributes.email;
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo"></img>
      <Navbar.Brand as={Link} to="/">
        {t("Home")}
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/unitcircle" >
          {t("UnitCircle")}
        </Nav.Link>
      </Nav>
      <Navbar.Toggle />
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/profile/groups/new">
          Create group
        </Nav.Link>
      </Nav>
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/about">
          About
        </Nav.Link>
      </Nav>
      <Navbar.Collapse className="justify-content-end">
        <ChangeLanguage />
        {email ? (
          <Nav.Link as={Link} to="/profile">
            {email}
          </Nav.Link>
        ) : (
          <Nav className="justify-content-end">
            <Nav.Link as={Link} to="/login">
              {t("log in")}
            </Nav.Link>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default withTranslation()(NavBar);
