import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Form from "../../components/Form";
import { RedirectContext } from "../../state/redirect.state";

const HomeScreen = (props) => {
  const { user, history } = props;
  const { redirectUrl } = useContext(RedirectContext);

  useEffect(() => {
    setTimeout(() => {
      if (user && redirectUrl) {
        history.push(redirectUrl);
      }
    }, 500);
  }, [user, redirectUrl]);
  
  const [formValues, setFormValues] = useState({
    numberOfSigns: 1,
    sign1: 1,
    sign2: 0,
    sign3: 0,
    sign4: 0,
    maxNumbers: 10,
    numberOfProblems: 10,
    problemType: "Mental math",
  });

  const handleChange = (value, field) => {
    setFormValues((formValues) => ({
      ...formValues,
      [field]: value,
    }));
  };

  const start = () => {
    const { sign1, sign2, sign3, sign4 } = formValues;
    if (!(sign1 || sign2 || sign3 || sign4)) {
      alert("you have to choose at least one sign");
    } else {
      history.push({
        pathname: "/game",
        state: { conditions: formValues },
      });
    }
  };

  return (
    <Form handleChange={handleChange} handleClick={start} values={formValues} />
  );
};

export default withRouter(HomeScreen);
