import { request } from '../utils/request';

export const getGroups = async () => {
  try {
    return await request(`/groups`);
  } catch (error) {
    throw error;
  }
};

export const getGroup = async ({ groupId, start, end }) => {
  try {
    return await request(`/groups/details/${groupId}?start=${start}&end=${end}`);
  } catch (error) {
    throw error;
  }
};

export const getAvailable = async () => {
  try {
    return await request(`/groups/available`);
  } catch (error) {
    throw error;
  }
};

export const createGroup = async (params) => {
  try {
    return await request(
      '/groups',
      {
        method: 'POST',
      },
      { ...params }
    );
  } catch (error) {
    throw error;
  }
};

export const joinGroup = async (groupId, pinCode) => {
  try {
    return await request(
      `/groups/join`,
      {
        method: 'POST',
      },
      { groupId, pinCode }
    );
  } catch (error) {
    console.log('🚀 ~ file: group.js ~ line 43 ~ joinGroup ~ error', error);
    throw error;
  }
};

export const joinGroupByHash = async (groupHash) => {
  try {
    return await request(
      `/groups/join-by-hash`,
      {
        method: 'POST',
      },
      { groupHash }
    );
  } catch (error) {
    console.log('🚀 ~ file: group.js ~ line 68 ~ joinGroupByHash ~ error', error);
    throw error;
  }
};

export const leaveGroup = async (groupId) => {
  try {
    return await request(
      `/groups/leave`,
      {
        method: 'POST',
      },
      { groupId }
    );
  } catch (error) {
    console.log('🚀 ~ file: group.js ~ line 58 ~ leaveGroup ~ error', error);
    throw error;
  }
};

export const removeGroup = async (groupId) => {
  try {
    return await request(`/groups/${groupId}`, {
      method: 'DELETE',
    });
  } catch (error) {
    throw error;
  }
};
