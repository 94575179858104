import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      first: "Welcome to math trainer",
      "log in": "log in",
      continue: "Start",
      Home: "Math Trainer",
      Actions: "Operations",
      Actions_explanation: "choose one or more",
      Max_value: "Max value",
      Max_value_explanation:
        "choose maximum value of numbers that are going to appear in the problems",
      Number_of_signs: "Number of signs",
      Number_of_signs_explanation:
        "choose how many signs are going to be in the problems",
      Number_of_problems: "Number of problems",
      Number_of_problems_explanation:
        "choose how many problems are going to be in the game",
      Start: "Start",
      New_game: "New game",
      Problem: "Problem",
      Your_answer: "Your answer",
      Answer: "Answer",
      Score: "Score",
      Wrong_answers: "Wrong answers",
      Right_answers: "Right answers",
      Total_solved: "Total solved",
      Call_to_log_in:
        "To save your results create an account or use an existing one",
      ProblemType: "Problem type",
      MentalMath: "Mental math",
      SystemsOfEquations: "Systems of equations",
      UnitCircle: "Unit circle",
    },
  },
  rus: {
    translation: {
      first: "Привет",
      "log in": "log in",
      continue: "продолжить",
      Home: "Math Trainer",
      Actions: "Действия",
      Max_value: "Максимальное значение",
      Number_of_signs: "Количество знаков",
      Number_of_problems: "Количество примеров",
      Start: "Начать",
      New_game: "Новая игра",
      Problem: "Задача",
      Your_answer: "Ваш ответ",
      Answer: "Правильный ответ",
      Score: "Счет",
      Wrong_answers: "Неправильные ответы",
      Right_answers: "Правильные ответы",
      Total_solved: "Всего задач",
      Actions_explanation: "выберите одно или несколько",
      Max_value_explanation:
        "выберите максимальное значение чисел, которые будут в примерах",
      Number_of_signs_explanation: "выберите количество знаков в примерах",
      Number_of_problems_explanation: "выберите количество примеров в игре",
      Call_to_log_in: "Чтобы сохранить результат создайте аккаунт или войдите",
      ProblemType: "Тип задач",
      MentalMath: "Ментальная математка",
      SystemsOfEquations: "Системы уравнений",
      UnitCircle: "Eдиничная окружность"
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: ["en", "rus"],

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
