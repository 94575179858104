import React, { useState, useCallback, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import * as dayjs from 'dayjs';
import styled from '@emotion/styled';

import { getGroupDetails } from '../../actions';
import UserListItem from './UserListItem';
import WeekSelector from '../../../../components/WeekSelector';
import { WEEK_DAYS } from './UserListItem';
import { WEEK_START_DAY_FORMAT } from '../../../../constants';

const currentWeekStartDay = () => {
  return dayjs().startOf('week').format(WEEK_START_DAY_FORMAT);
};

const weekRange = (weekStartDay) => {
  const start = dayjs(weekStartDay).startOf('day').format(WEEK_START_DAY_FORMAT);
  const end = dayjs(weekStartDay).add(6, 'days').endOf('day').format(WEEK_START_DAY_FORMAT);
  return { start, end };
};

const formatData = (data) => {
  return {
    name: data.groupDetails.name,
    createdAt: data.groupDetails.createdAt,
    owner: data.groupDetails.owner,
    users: data.users,
  };
};

const GroupDetails = (props) => {
  const { user, history } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [week, setWeek] = useState(currentWeekStartDay());
  let { id: groupId } = useParams();

  const fetchData = useCallback(() => {
    if (user && groupId) {
      const { start, end } = weekRange(week);
      getGroupDetails({ groupId, start, end })
        .then((groupDetails) => {
          groupDetails && setData(formatData(groupDetails, start, end));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('🚀 ~ file: Groups.jsx ~ line 21 ~ fetchData ~ error', error);
        });

    }
  }, [user, groupId, week]);

  useEffect(() => {
    fetchData();
  }, [fetchData, week]);

  const onWeekChange = (weekStartDay) => {
    setWeek(weekStartDay);
  };

  if (!groupId || !data) return null;
  const { name, users, createdAt, owner } = data;
  return (
    <div className='block' style={{ minHeight: '50vh' }}>
      <Header className='row'>
        <BackLink onClick={() => history.goBack()}>{`< Back`}</BackLink>
        <h5>Group Details</h5>
      </Header>
      <h1>{name}</h1>
      <h4>created at {dayjs(createdAt).format('lll')}<br/> by {owner}</h4>
      <WeekSelector week={week} onChange={onWeekChange} minDate={createdAt} />
      {loading ? <div>Loading ...</div> : null}
      {!loading && users && users.length ? (
        <>
        <table>
          <thead>
            <tr>
              <th rowSpan='2'></th>
              <th rowSpan='2'>Name</th>
              <RowHeader colSpan='8'>Activity</RowHeader>
            </tr>
            <tr>
              {WEEK_DAYS.map((item) => (
                <RowHeader key={item}>{item}</RowHeader>
              ))}
              <RowHeader>Total</RowHeader>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <UserListItem key={`${user.user_id}`} user={user} week={week} index={index} />
            ))}
          </tbody>
        </table>
        <br/>
        <Hint>* games count / score</Hint>
        </>
      ) : null}
    </div>
  );
};

export default withRouter(GroupDetails);

const RowHeader = styled.th`
  text-align: center
`;

const Hint = styled.p`
  font-size: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const BackLink = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  margin-right: 20px;
  color: var(--primary);
  cursor: pointer;
  margin-bottom: 5px;
`;