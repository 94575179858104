export default function gameScorer(props) {
  const { rightAnswersCount, problemsCount, conditions } = props;
  const {
    numberOfSigns,
    sign1,
    sign2,
    sign3,
    sign4,
    maxNumbers,
    numberOfProblems,
  } = conditions;

  let score = (rightAnswersCount * numberOfProblems) / problemsCount;
  let k = 1;
  k = k * numberOfSigns * (sign1 + sign2 + sign3 + sign4);
  if (maxNumbers === 20) k *= 2;
  if (maxNumbers === 50) k *= 3;
  if (maxNumbers === 100) k *= 4;
  if (7 <= k && k <= 24) score *= 2;
  if (25 <= k && k <= 36) score *= 3;
  return score;
}
