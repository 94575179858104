import React, { useState, useEffect, useRef } from 'react';
import { useTable, usePagination } from 'react-table';
import { getGames } from '../actions';
import * as dayjs from 'dayjs';
import '../../../index.css';
import TablePagination from '../../../components/TablePagination';

const formatData = (items) => {
  return items.map((item) => ({
    colDate: dayjs(item.date).format('H:mm, D MMMM, YY'),
    colProblemsCount: item.problemsCount,
    colRightAnswersCount: item.rightAnswersCount,
    colScore: item.score,
  }));
};

const GamesTable = (props) => {
  const { user } = props;
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = useRef(0);
  const [hasNext, setHasNext] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'colDate', // accessor is the "key" in the data
      },
      {
        Header: 'Problems',
        accessor: 'colProblemsCount',
      },
      {
        Header: 'RightAnswers',
        accessor: 'colRightAnswersCount',
      },
      {
        Header: 'Score',
        accessor: 'colScore',
      },
    ],
    []
  );

  const fetchData = React.useCallback(() => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId === fetchIdRef.current && user) {
      const offset = pageSize * pageIndex
      getGames(pageSize, offset)
        .then((result) => {
          const { games, total } = result
          result && setHasNext(pageSize * (pageIndex + 1) < total);
          setData(formatData(games));
          setTotal(total)
        })
        .catch((error) => {
          console.log('🚀 ~ file: Profile.js ~ line 14 ~ useEffect ~ error', error);
        });

      setLoading(false);
    }
  }, [pageIndex, pageSize, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData, pageIndex, pageSize]);

  const next = () => {
    hasNext && setPageIndex((pageIndex) => pageIndex + 1);
  };

  const prev = () => {
    setPageIndex((pageIndex) => pageIndex - 1);
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: 10,
    },
    usePagination
  );
  if (!data || data.length === 0) {
    return (
      <div className='block' style={{ minHeight: '50vh' }}>
        <h1>Your games</h1>
        <p>You haven't played any game yet!</p>
      </div>
    );
  }
  return (
    <div className='block' style={{ minHeight: '50vh' }}>
      <h1>Your games ({total}) </h1>
      <br />
      <div className='table text'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            <tr>
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <td colSpan='10000'>Loading...</td>
              ) : null}
            </tr>
          </tbody>
        </table>
        <TablePagination
          pageIndex={pageIndex}
          hasNext={hasNext}
          pageSize={pageSize}
          next={next}
          prev={prev}
          onPageSizeChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        />
      </div>
    </div>
  );
};

export default GamesTable;
