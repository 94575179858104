import React from "react";

export default function PlusIcon({
  color = "black",
  size = 50,
  onClick,
  fieldName,
}) {
  const handleClick = () => {
    onClick(fieldName);
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g>
          <title>background</title>
          <rect
            fill="none"
            id="canvas_background"
            height="402"
            width="582"
            y="-1"
            x="-1"
          />
        </g>
        <g>
          <title>Layer 1</title>
          <g
            stroke="null"
            id="svg_1"
            fill={color}
            transform="translate(0,512) scale(0.10000000149011612,-0.10000000149011612) "
          >
            <path
              stroke="null"
              id="svg_2"
              d="m573.471085,5088.511984c-293.943966,-63.843033 -524.0449,-312.564851 -559.956606,-603.848691c-7.980379,-73.153476 -11.970569,-778.08697 -9.310442,-1995.094795l3.99019,-1882.039423l29.26139,-86.454108c89.114234,-260.692387 291.28384,-445.571171 545.325911,-498.773699c75.813602,-15.960758 385.718327,-19.950948 1967.163468,-19.950948c1274.200543,0 1900.660308,3.99019 1955.192899,14.630695c204.829732,35.911706 416.309781,192.859164 513.404394,379.068011c95.76455,183.548721 90.444297,41.231959 90.444297,2174.653327c0,2106.820104 3.99019,1977.803974 -81.133855,2152.042252c-85.124045,171.578152 -288.623714,325.865483 -486.80313,367.097442c-133.00632,27.931327 -3837.232323,27.931327 -3967.578516,-1.330063zm3956.93801,-356.456937c85.124045,-39.901896 147.637015,-102.414866 191.5291,-194.189227l34.581643,-73.153476l0,-1908.640687l0,-1908.640687l-31.921517,-69.163286c-17.290822,-38.571833 -58.522781,-95.76455 -93.104424,-127.686067c-119.705688,-111.725309 61.182907,-103.744929 -2109.48023,-99.75474l-1928.591635,3.99019l-62.51297,35.911706c-33.25158,21.281011 -82.463918,61.182907 -109.065182,91.774361c-94.434487,110.395245 -87.784171,-31.921517 -87.784171,2076.22865c0,1683.860007 2.660126,1905.980561 19.950948,1949.872646c47.882275,114.385435 131.676256,196.849353 242.071502,236.751249c41.231959,14.630695 387.04839,17.290822 1959.183089,17.290822l1908.640687,1.330063l66.50316,-31.921517z"
            />
            <path
              stroke="null"
              id="svg_3"
              d="m2463.490888,4068.353512c-17.290822,-7.980379 -45.222149,-33.25158 -59.852844,-53.202528c-27.931327,-37.24177 -27.931327,-51.872465 -31.921517,-662.371472l-3.99019,-623.799639l-605.178755,0c-645.08065,0 -659.711346,-1.330063 -716.904063,-66.50316c-19.950948,-22.611074 -26.601264,-47.882275 -26.601264,-106.405056c0,-58.522781 6.650316,-83.793981 26.601264,-106.405056c57.192717,-65.173097 71.823413,-66.50316 718.234126,-66.50316l605.178755,0l0,-594.538249c0,-353.79681 5.320253,-615.81926 13.300632,-643.750587c22.611074,-79.803792 69.163286,-111.725309 160.937647,-111.725309c70.493349,0 82.463918,3.99019 121.035751,43.892085l43.892085,42.562022l3.99019,631.780018l3.99019,631.780018l605.178755,0c655.721156,0 663.701535,1.330063 720.894253,73.153476c54.532591,67.833223 31.921517,198.179416 -42.562022,247.391755c-22.611074,14.630695 -140.986699,19.950948 -655.721156,22.611074l-627.789829,3.99019l-3.99019,631.780018l-3.99019,633.110082l-49.212338,45.222149c-41.231959,37.24177 -61.182907,45.222149 -106.405056,45.222149c-31.921517,0 -71.823413,-7.980379 -89.114234,-17.290822z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
