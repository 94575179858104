import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const WelcomeScreen = (props) => {
  const { t, user } = props;

  return (
    <div>
      <div className="circle" />
      <div className="circle2" />
      <div className="block" style={{ minHeight: "50vh" }}>
        <h1>{t("first")}</h1>
        <ul>
          <li>
            <NavLink to="/">{t("continue")}</NavLink>
          </li>
          {!user && (
            <li>
              <NavLink to="/login">{t("log in")}</NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(WelcomeScreen);
