import problemSolver from '../problemSolver';

function makeSignsArr(conditions) {
  let signs = [];
  if (conditions.sign1) signs.push('+');
  if (conditions.sign2) signs.push('-');
  if (conditions.sign3) signs.push('*');
  if (conditions.sign4) signs.push('/');
  return signs;
}

function randomNumber(from, to) {
  let answ = Math.floor(Math.random() * (to - from)) + from;
  return answ;
}

export default function mentalMathGenerator(conditions) {
  let problem = '';
  let isProblemCorrect = 'WRONG';
  let availableSigns = makeSignsArr(conditions);
  while (isProblemCorrect === 'WRONG') {
    problem = '';
    for (let i = 0; i < conditions.numberOfSigns; i++) {
      problem += randomNumber(0, conditions.maxNumbers);
      problem += availableSigns[randomNumber(0, availableSigns.length)];
    }
    problem += randomNumber(0, conditions.maxNumbers);
    problem += '=';
    isProblemCorrect = problemSolver(problem);
  }
  return problem;
}
