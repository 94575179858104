let problem;
let i;
let isWrong = false;

function next() {
  i--;
}
function current() {
  if (i === -1) return ".";
  return problem[i];
}
function digital() {
  return current() - "0";
}
function number() {
  let step = 1;
  let x = 0;
  if (!(0 <= digital() && digital() <= 9)) {
    return "WRONG";
  }
  while (0 <= digital() && digital() <= 9) {
    x += step * digital();
    step *= 10;
    next();
  }
  return x;
}
function mul() {
  if (current() === ")") {
    next();
    let x = expr();
    next();
    return x;
  }
  return number();
}
function summ() {
  let x = mul();
  if (!(Number.isInteger(x) && x >= 0)) isWrong = true;
  if (current() === "/") {
    if (x === 0) isWrong = true;
    next();
    return summ() / x;
  } else if (current() === "*") {
    next();
    return summ() * x;
  }
  return x;
}
function expr() {
  let x = summ();
  if (!(Number.isInteger(x) && x >= 0)) isWrong = true;
  if (current() === "+") {
    next();
    return expr() + x;
  } else if (current() === "-") {
    next();
    return expr() - x;
  }
  return x;
}
export default function problemSolver(s) {
  problem = s.substring(0, s.length);
  i = problem.length - 2;
  isWrong = false;
  let answ = expr();
  if (i !== -1 || isWrong || answ < 0) return "WRONG";
  else return answ;
}
