import React, { useRef, useEffect } from "react";
import WithSizes from "react-sizes";
import "../../index.css";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { NavLink } from "react-router-dom";
import {BlockMath} from "react-katex"
import { getAnswerInTex, getProblemInTex } from "../../utils/getProblemInTex/getProblemInTex";
import checkAnswer from "../../utils/checkAnswer/checkAnswer";

const YELLOW = "#fff400";
const GREEN = "#14a76c";
const RED = "#ff652f";

function Results(props) {
  const { t, conditions, handleClick, score, user } = props;
  const {
    problemType,
    totalNumberOfProblems,
    problems,
    userAnswers,
    rightAnswers,
  } = conditions;
  const buttonRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      buttonRef.current && buttonRef.current.focus();
    }, 600);
    return () => clearTimeout(timer);
  }, []);

  let results = [];
  let rightAnswersCount = 0;
  for (let i = 0; i < totalNumberOfProblems; i++) {
    let problem = problems[i];
    let userAnswer = userAnswers[i];
    let myAnswer = rightAnswers[i];
    let answerColor = RED;
    if (checkAnswer(userAnswer, myAnswer)) {
      answerColor = GREEN;
      rightAnswersCount++;
    }
    results.push(
      <tr key={`${i}`}>
        <td style={{ color: YELLOW }}><BlockMath math={getProblemInTex(problem,problemType)}/></td>
        <td style={{ color: answerColor }}><BlockMath math={getAnswerInTex(userAnswer,problemType)}/></td>
        <td style={{ color: YELLOW }}><BlockMath math={getAnswerInTex(myAnswer,problemType)}/></td>
      </tr>
    );
  }
  return (
    <div>
      <div className="circle" />
      <div className="circle2" />
      <div className="block-row">
        <div className="block small-block">
          <p className="text" style={{ fontSize: 25, textAlign: "left" }}>
            {t("Total_solved")}: {totalNumberOfProblems}
            <br />
            {t("Right_answers")}: {rightAnswersCount}
            <br />
            {t("Wrong_answers")}: {totalNumberOfProblems - rightAnswersCount}
            <br />
            {t("Score")}: {score}
          </p>
        </div>
      </div>
      <div className="block-row">
        <div className="block">
          <table className="table">
            <thead>
              <tr style={{ color: YELLOW }}>
                <th>{t("Problem")}</th>
                <th>{t("Your_answer")}</th>
                <th>{t("Answer")}</th>
              </tr>
            </thead>
            <tbody>{results}</tbody>
          </table>
        </div>
      </div>
      <div className="block-row">
        <button ref={buttonRef} className="button" onClick={handleClick}>
          {t("New_game")}
        </button>
      </div>
      {!user ? (
        <div className="block-row block">
          <p style={{ fontSize: 18 }}>{t("Call_to_log_in")}</p>
          <NavLink to="/login" className="text_in_button">
            {t("log in")}
          </NavLink>
        </div>
      ) : null}
    </div>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
});

export default compose(withTranslation(), WithSizes(mapSizesToProps))(Results);
